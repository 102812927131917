import React, { useState, useEffect, useRef, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { fetchTable } from "Actions/tableActions";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import api from "API";
import Form from "antd/es/form";
import message from "antd/es/message";
import Button from "antd/es/button";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import CancelButton from "Components/CancelButton";
import Currency from "Components/Inputs/Currency";
import Modal from "antd/es/modal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";
import Divider from "antd/es/divider";
import currencyFormatter from "Utils/currencyFormatter";
import ExpenseColumn from "./ExpenseColumn";
import sumBy from "Utils/sumBy";
import percentFormatter from "Utils/percentFormatter";
import Commissions from "./Commissions";

// import { useReactToPrint } from "react-to-print";
import PrintBreakdown from "./PrintBreakdown";
import ProjectDetails from "./ProjectDetails";
import Document from "./Document";
import ScopeDetails from "./ScopeDetails";
// import BreakdownDetailsExpected from "./BreakdownDetailsExpected";
import PricingDetails from "./PricingDetails";
import CommissionDetails from "./CommissionDetails";
import styles from "./styles.module.scss";
import Post from "Icons/Post";
import openInNewTab from "Utils/openInNewTab";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

import Affix from "antd/es/affix";

import HorizontalScroll from "Components/HorizontalScroll";

import ProcessFeedback from "Components/ProcessFeedback";

const messageKey = "messageKey";

const steps = [
  "Submitting Form",
  "Creating Post",
  "Creating Commissions",
  "Notifying Salesmen",
  "Done!",
];

dayjs.extend(isBetween);

const NeedsBrokedown = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  setTitle,
  type,
}) => {
  const formDetails = useSelector(
    (state) => state.formState.needsBrokedown.formDetails
  );
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const printRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => printRef.current,
  // });

  const handlePrint = () => {
    var divElementContents = document.getElementById("printContent").innerHTML;
    var windows = window.open("", "", "height=800, width=1000");
    windows.document.write("<html>");
    windows.document.write("<body>");
    windows.document.write(divElementContents);
    windows.document.write("</body></html>");
    windows.document.close();
    windows.print();
  };
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [employeesObj, setEmployeeesObj] = useState({});

  const [scopes, setScopes] = useState([]);
  const [details, setDetails] = useState({});
  const [collectionsTotal, setCollectionsTotal] = useState(0);
  const [dumpsterTotal, setDumpsterTotal] = useState(0);
  const [extraTotal, setExtraTotal] = useState(0);
  const [laborTotal, setLaborTotal] = useState(0);
  const [materialTotal, setMaterialTotal] = useState(0);
  const [permitTotal, setPermitTotal] = useState(0);
  const [stockTotal, setStockTotal] = useState(0);
  const [overHead, setOverHead] = useState(0);
  const [officeFee, setOfficeFee] = useState(0);
  const [officeFeeRate, setOfficeFeeRate] = useState(0);
  const [managerFeeRate, setManagerFeeRate] = useState(0);
  const [balance, setBalance] = useState(0);
  const [profitPercentage, setProfitPercentage] = useState(0);
  const [managerFee, setManagerFee] = useState(0);
  const [regularCommission, setRegularCommission] = useState(0);
  const [salaryCommission, setSalaryCommission] = useState(0);
  const [managerCommission, setManagerCommission] = useState(0);
  const [newRegularComission, setNewRegularComission] = useState(0);
  const [joshHRegularComission, setJoshHRegularCommission] = useState(0);
  // const [managerCommission, setManagerCommission] = useState(0);
  const [insurance, setInsurance] = useState(false);
  const [documents, setDocuments] = useState([]);

  const [creditCardTotal, setCreditCardTotal] = useState(0);
  const [financingTotal, setFinancingTotal] = useState(0);
  const [warehouseTotal, setWarehouseTotal] = useState(0);
  const [warrantyTotal, setWarrantyTotal] = useState(0);
  const [guttersTotal, setGuttersTotal] = useState(0);
  // const [promotionEligible, setPromotionEligible] = useState(false);

  const [expenses, setExpenses] = useState([]);

  const [scrollContainer, setScrollContainer] = useState(null);

  const _user = useSelector((state) => state.authState._user);
  const [feedBack, setFeedBack] = useState({
    active: "Submitting Form",
    progress: 0,
  });

  const documentRef = useRef();

  useEffect(() => {
    fetchMentions();
    fetchDetails();
    if (formDetails.brokedown === "No" || !formDetails.brokedown) {
      setTitle("Create Breakdown");
    } else {
      setTitle("Edit Breakdown");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchMentions = async () => {
    try {
      const res = await api.get("/employees");
      let obj = {};
      for (const o of res.data) {
        obj[o._id] = o;
      }
      unstable_batchedUpdates(() => {
        setEmployees(res.data);
        setEmployeeesObj(obj);
      });
    } catch (err) {
      console.log("err", err);
      message.error("Error occured retrieving staff list");
    }
  };

  const fetchDetails = async () => {
    try {
      const res = await api.get(`/needsBrokedown/get/${formDetails._id}`);
      form.setFieldsValue({
        collections: res.data.collections,
        dumping: res.data.dumpsters,
        permits: res.data.permits,
        materials: res.data.materials,
        labor: res.data.labors,
        extra: res.data.extras,
        overHead: res.data.overHead,
        salesman: res.data.project.salesObj,
        commissions: res.data.commissions,
        stock: 0,
        creditCards: res.data.creditCards,
        financings: res.data.financings,
        warehouses: res.data.warehouses,
        warranties: res.data.warranties,
        gutters: res.data.gutters,
      });

      // const _promotionEligible = dayjs(res.data.project.contractDate).isBetween(
      //   dayjs("2022-08-01"),
      //   dayjs("2022-08-31")
      // );

      unstable_batchedUpdates(() => {
        setScopes(res.data.scopes);
        setDetails(res.data.project);
        // setPromotionEligible(_promotionEligible);
        setCollectionsTotal(res.data.collectionsTotal);
        setDumpsterTotal(res.data.dumpsterTotal);
        setExtraTotal(res.data.extraTotal);
        setLaborTotal(res.data.laborTotal);
        setMaterialTotal(res.data.materialTotal);
        setPermitTotal(res.data.permitTotal);
        setOverHead(res.data.overHead);
        setOfficeFeeRate(res.data.officeFeeRate);
        setManagerFeeRate(res.data.managerFeeRate);
        setBalance(res.data.balance);
        setProfitPercentage(res.data.profitPercentage);
        setManagerFee(res.data.managerFee);
        setOfficeFee(res.data.officeFee);
        setRegularCommission(res.data.regularCommission);
        setSalaryCommission(res.data.salaryCommission);
        setNewRegularComission(res.data.newRegularComission);
        setJoshHRegularCommission(res.data.joshHRegularComission);
        setManagerCommission(res.data.managerCommission);
        setInsurance(res.data.insurance);
        setDocuments(res.data.documents);
        setCreditCardTotal(res.data.creditCardTotal);
        setFinancingTotal(res.data.financingTotal);
        setWarehouseTotal(res.data.warehouseTotal);
        setWarrantyTotal(res.data.warrantyTotal);
        setGuttersTotal(res.data.guttersTotal);
        setExpenses(res.data.expenses);
      });
    } catch (err) {
      console.log("err", err);
      message.error("Error getting details");
    }
  };

  const updateFeedBack = (feedBack) => {
    setFeedBack(feedBack);
  };

  const onFinish = useCallback(async () => {
    try {
      let values = await form.validateFields();
      window.socket.on(`${_user}-breakdown-progress`, updateFeedBack);
      unstable_batchedUpdates(() => {
        setFeedBack({
          active: "Submitting Form",
          progress: 1,
        });
        setLoading(true);
      });

      // message.loading("Saving Breakdown...", 0);
      message.loading({
        content: "Saving breakdown...",
        duration: 0,
        key: messageKey,
      });

      const _commissions = [];

      values.customerName = formDetails.customerName;
      values.projectAddress = formDetails.projectAddress;
      values._projectId = formDetails._id;
      values.customerId = formDetails.customerId;
      values.origin = details.origin;
      values.submitted = new Date();
      values._userIds = [];
      values.salesmanEmails = [];
      values.salesman = [];
      values.commissionTotal = 0;
      values.notifications = [];
      values.managerCommission = 0;

      for (const c of values.commissions) {
        if (c.type !== "Manager Commission") {
          values._userIds.push(c.employee);
          values.salesmanEmails.push(employeesObj[c.employee].email);
          values.salesman.push(employeesObj[c.employee].name);

          values.notifications.push({
            user: {
              _user: c.employee,
              name: employeesObj[c.employee].name,
              email: employeesObj[c.employee].email,
            },
            amount: c.amount,
          });

          values.commissionTotal += c.amount;
        } else if (c.type === "Manager Commission") {
          values.managerCommission += c.amount;
        }

        _commissions.push({
          transactionDate: new Date(),
          activity: c.amount,
          type: c.type,
          userName: employeesObj[c.employee].name,
          userEmail: employeesObj[c.employee].email,
          _userId: c.employee,
          customerId: formDetails.customerId,
          _projectId: formDetails._id,
        });
      }

      values.commissions = _commissions;
      values.materials = materialTotal;
      values.labor = laborTotal;
      values.overhead = overHead;
      values.dumping = dumpsterTotal;
      values.permit = permitTotal;
      values.extra = extraTotal;
      values.stock = stockTotal;
      values.balance = balance;
      values.insurance = insurance;
      values.officeFee = officeFee;

      values.creditCards = creditCardTotal;
      values.financing = financingTotal;
      values.warhouse = warehouseTotal;
      values.warranty = warrantyTotal;
      values.gutters = guttersTotal;

      values.expensesTotal =
        materialTotal +
        laborTotal +
        overHead +
        dumpsterTotal +
        permitTotal +
        stockTotal +
        creditCardTotal +
        financingTotal +
        warehouseTotal +
        warrantyTotal +
        guttersTotal +
        extraTotal;
      values.collectionsTotal = collectionsTotal;
      // values.managerCommission = managerFee;

      values.materialPercent = Math.round(
        (materialTotal / collectionsTotal) * 100
      );
      values.laborPercent = Math.round((laborTotal / collectionsTotal) * 100);
      values.overheadPercent = Math.round((overHead / collectionsTotal) * 100);
      values.dumpingPercent = Math.round(
        (dumpsterTotal / collectionsTotal) * 100
      );
      values.permitPercent = Math.round((permitTotal / collectionsTotal) * 100);
      values.stockPercent = Math.round((stockTotal / collectionsTotal) * 100);
      values.extraPercent = Math.round((extraTotal / collectionsTotal) * 100);
      values.profitPercent = Math.round((balance / collectionsTotal) * 100);

      values.creditCardPercent = Math.round(
        (creditCardTotal / collectionsTotal) * 100
      );
      values.financingPercent = Math.round(
        (financingTotal / collectionsTotal) * 100
      );
      values.warehousePercent = Math.round(
        (warehouseTotal / collectionsTotal) * 100
      );
      values.warrantyPercent = Math.round(
        (warrantyTotal / collectionsTotal) * 100
      );
      values.guttersPercent = Math.round(
        (guttersTotal / collectionsTotal) * 100
      );

      values.companyProfit =
        balance - values.commissionTotal - values.managerCommission;

      if (
        details.propertyType !== "New Construction" &&
        profitPercentage > 20
      ) {
        values.blockBonusMonthly = balance * 0.02;
        values.blockBonusYearly = balance * 0.005;
      } else {
        values.blockBonusMonthly = 0;
        values.blockBonusYearly = 0;
      }

      await api.post("/needsBrokedown/save", values);

      dispatch(
        fetchTable({
          table,
          col,
          order,
          search,
          activeFilter,
          fetching: false,
        })
      );

      unstable_batchedUpdates(() => {
        setUnsavedChanges(false);
        setLoading(false);
        setFeedBack({
          active: "Done!",
          progress: 100,
        });
      });

      window.socket.removeAllListeners(`${_user}-breakdown-progress`);
      // message.success("Breakdown saved");
      message.success({
        content: "Breakdown saved",
        duration: 1.5,
        key: messageKey,
      });
      handleClose(true);
    } catch (err) {
      setLoading(false);
      setFeedBack({
        active: "Submitting Form",
        progress: 0,
      });
      // message.error("Error saving breakdown");
      message.error({
        content: "Error saving breakdown",
        duration: 1.5,
        key: messageKey,
      });
      window.socket.removeAllListeners(`${_user}-breakdown-progress`);
      console.log("err", err);
    }
  }, [
    _user,
    details,
    dispatch,
    table,
    col,
    order,
    search,
    activeFilter,
    setUnsavedChanges,
    handleClose,
    balance,
    collectionsTotal,
    dumpsterTotal,
    employeesObj,
    extraTotal,
    formDetails._id,
    formDetails.customerId,
    formDetails.customerName,
    formDetails.projectAddress,
    insurance,
    laborTotal,
    // managerFee,
    materialTotal,
    officeFee,
    overHead,
    permitTotal,
    profitPercentage,
    stockTotal,
    form,
    creditCardTotal,
    financingTotal,
    warehouseTotal,
    warrantyTotal,
    guttersTotal,
  ]);

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error saving breakdown");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const handleOverheadChange = async (value) => {
    try {
      const values = await form.getFieldsValue([
        "materials",
        "labor",
        "dumping",
        "permits",
        "extra",
        "stock",
        "collections",
        "creditCards",
        "financings",
        "gutters",
        "warehouses",
        "warranties",
      ]);

      console.log("value", value);

      let _materials = values.materials || 0;
      let _overHead = value || 0;
      let _labor = values.labor || 0;
      let _dumping = values.dumping || 0;
      let _permits = values.permits || 0;
      let _extra = values.extra || 0;
      let _stock = values.stock || 0;
      let _collections = values.collections || 0;

      let _creditCards = values.creditCards || 0;
      let _financings = values.financings || 0;
      let _gutters = values.gutters || 0;
      let _warehouses = values.warehouses || 0;
      let _warranties = values.warranties || 0;

      const salesLength = details.salesObj.length;

      const _collectionsTotal =
        _collections === 0
          ? _collections
          : _collections.length > 0
          ? sumBy(_collections, "amount")
          : 0;
      const _materialsTotal =
        _materials === 0
          ? _materials
          : _materials.length > 0
          ? sumBy(_materials, "amount")
          : 0;
      const _laborTotal =
        _labor === 0 ? _labor : _labor.length > 0 ? sumBy(_labor, "amount") : 0;
      const _dumpingTotal =
        _dumping === 0
          ? _dumping
          : _dumping.length > 0
          ? sumBy(_dumping, "amount")
          : 0;
      const _permitsTotal =
        _permits === 0
          ? _permits
          : _permits.length > 0
          ? sumBy(_permits, "amount")
          : 0;
      const _extraTotal =
        _extra === 0 ? _extra : _extra.length > 0 ? sumBy(_extra, "amount") : 0;

      const _creditCardsTotal =
        _creditCards === 0
          ? _creditCards
          : _creditCards.length > 0
          ? sumBy(_creditCards, "amount")
          : 0;
      const _financingsTotal =
        _financings === 0
          ? _financings
          : _financings.length > 0
          ? sumBy(_financings, "amount")
          : 0;
      const _guttersTotal =
        _gutters === 0
          ? _gutters
          : _gutters.length > 0
          ? sumBy(_gutters, "amount")
          : 0;
      const _warehousesTotal =
        _warehouses === 0
          ? _warehouses
          : _warehouses.length > 0
          ? sumBy(_warehouses, "amount")
          : 0;
      const _warrantiesTotal =
        _warranties === 0
          ? _warranties
          : _warranties.length > 0
          ? sumBy(_warranties, "amount")
          : 0;

      let _balance =
        _collectionsTotal -
        _overHead -
        _materialsTotal -
        _laborTotal -
        _dumpingTotal -
        _permitsTotal -
        _extraTotal -
        _creditCardsTotal -
        _financingsTotal -
        _guttersTotal -
        _warehousesTotal -
        _warrantiesTotal -
        _stock;

      let _profitPercentage = (_balance / _collectionsTotal) * 100;

      // TODO: swap out new logic for decreased commissions if _profitPercentage is < 17
      // const contractDate = new Date(details.contractDate);
      // const cutoff17Under17 = new Date(
      //   "Tue Apr 30 2024 20:00:00 GMT-0400 (Eastern Daylight Time)"
      // );
      // const cutoff17Under17 = new Date();
      // if (
      //   // _profitPercentage < 17 &&
      //   // details.propertyType !== "New Construction" &&
      //   contractDate > cutoff17Under17
      // ) {
      //   // _overHead = _collectionsTotal * 0.17;
      //   if (details.propertyType === "New Construction") {
      //     // _overHead = _collectionsTotal * 0.13;
      //     _overHead = _collectionsTotal * 0.15;
      //   } else {
      //     // _overHead = _collectionsTotal * 0.13;
      //     _overHead = _collectionsTotal * 0.15;
      //   }

      //   _balance =
      //     _collectionsTotal -
      //     _overHead -
      //     _materialsTotal -
      //     _laborTotal -
      //     _dumpingTotal -
      //     _permitsTotal -
      //     _extraTotal -
      //     _creditCardsTotal -
      //     _financingsTotal -
      //     _guttersTotal -
      //     _warehousesTotal -
      //     _warrantiesTotal -
      //     _stock;

      //   _profitPercentage = (balance / collectionsTotal) * 100;
      // }

      const _managerFee = managerFeeRate * _balance;
      const _officeFee = officeFeeRate * _balance;
      const _regularCommission =
        officeFeeRate === 0
          ? (_balance * 0.5) / salesLength
          : (_balance * 0.3) / salesLength;
      const _salaryCommission =
        officeFeeRate === 0
          ? (_balance * 0.25) / salesLength
          : (_balance * 0.15) / salesLength;
      const _joshHRegularComission =
        officeFee === 0
          ? (balance * 0.45) / salesLength
          : (balance * 0.3) / salesLength;
      const _newRegularComission =
        officeFee === 0
          ? (balance * 0.4) / salesLength
          : (balance * 0.25) / salesLength;
      const _managerCommission =
        officeFeeRate === 0
          ? (_balance * 0.55) / salesLength
          : (_balance * 0.35) / salesLength;
      const ncCommission =
        officeFeeRate === 0
          ? (balance * 0.06) / salesLength
          : (balance * 0.03) / salesLength;

      const commissions = [];
      for (const s of details.salesObj) {
        let obj = {};
        obj.employee = s._user;
        obj.type = "Commission";
        if (
          s.name === "Ryan Balcom" ||
          s.name === "Rick Zuniga" ||
          s.name === "Josh Bell"
        ) {
          // Do not assign a commission
        } else if (s.name === "Joshua Homrich") {
          obj.amount = _joshHRegularComission;
          commissions.push(obj);
        } else if (
          s.name === "Andrew Story" ||
          s.name === "Tavis Polinskey" ||
          s.name === "Marcus Ferrell"
        ) {
          obj.amount = _newRegularComission;
          commissions.push(obj);
        } else if (s.name === "Pat Cisler") {
          obj.amount = ncCommission;
          commissions.push(obj);
        } else if (s.name === "Josh Bell") {
          obj.amount = _managerCommission;
          commissions.push(obj);
        } else {
          obj.amount = _regularCommission;
          commissions.push(obj);
        }
      }

      if (managerFeeRate !== 0) {
        commissions.push({
          employee: "5ce185e8ce894a0031b7c7bb",
          type: "Manager Commission",
          amount: _managerFee,
        });
      }

      console.log("_overHead", _overHead);

      form.setFieldsValue({
        commissions,
        // overHead: _overHead,
      });

      unstable_batchedUpdates(() => {
        setBalance(_balance);
        setOverHead(_overHead);
        setMaterialTotal(_materialsTotal);
        setLaborTotal(_laborTotal);
        setDumpsterTotal(_dumpingTotal);
        setPermitTotal(_permitsTotal);
        setExtraTotal(_extraTotal);
        setCollectionsTotal(_collectionsTotal);
        setStockTotal(_stock);
        setProfitPercentage(_profitPercentage);

        setCreditCardTotal(_creditCardsTotal);
        setFinancingTotal(_financingsTotal);
        setWarehouseTotal(_warehousesTotal);
        setWarrantyTotal(_warrantiesTotal);
        setGuttersTotal(_guttersTotal);

        setManagerFee(_managerFee);
        setOfficeFee(_officeFee);
        setRegularCommission(_regularCommission);
        setSalaryCommission(_salaryCommission);
        setNewRegularComission(_newRegularComission);
        setManagerCommission(_managerCommission);
        setJoshHRegularCommission(_joshHRegularComission);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleExpenseChange = async () => {
    try {
      const values = await form.getFieldsValue([
        "materials",
        "overHead",
        "labor",
        "dumping",
        "permits",
        "extra",
        "stock",
        "collections",
        "creditCards",
        "financings",
        "gutters",
        "warehouses",
        "warranties",
      ]);

      let _materials = values.materials || 0;
      let _overHead = values.overHead || 0;
      let _labor = values.labor || 0;
      let _dumping = values.dumping || 0;
      let _permits = values.permits || 0;
      let _extra = values.extra || 0;
      let _stock = values.stock || 0;
      let _collections = values.collections || 0;

      let _creditCards = values.creditCards || 0;
      let _financings = values.financings || 0;
      let _gutters = values.gutters || 0;
      let _warehouses = values.warehouses || 0;
      let _warranties = values.warranties || 0;

      const salesLength = details.salesObj.length;

      const _collectionsTotal =
        _collections === 0
          ? _collections
          : _collections.length > 0
          ? sumBy(_collections, "amount")
          : 0;
      const _materialsTotal =
        _materials === 0
          ? _materials
          : _materials.length > 0
          ? sumBy(_materials, "amount")
          : 0;
      const _laborTotal =
        _labor === 0 ? _labor : _labor.length > 0 ? sumBy(_labor, "amount") : 0;
      const _dumpingTotal =
        _dumping === 0
          ? _dumping
          : _dumping.length > 0
          ? sumBy(_dumping, "amount")
          : 0;
      const _permitsTotal =
        _permits === 0
          ? _permits
          : _permits.length > 0
          ? sumBy(_permits, "amount")
          : 0;
      const _extraTotal =
        _extra === 0 ? _extra : _extra.length > 0 ? sumBy(_extra, "amount") : 0;

      const _creditCardsTotal =
        _creditCards === 0
          ? _creditCards
          : _creditCards.length > 0
          ? sumBy(_creditCards, "amount")
          : 0;
      const _financingsTotal =
        _financings === 0
          ? _financings
          : _financings.length > 0
          ? sumBy(_financings, "amount")
          : 0;
      const _guttersTotal =
        _gutters === 0
          ? _gutters
          : _gutters.length > 0
          ? sumBy(_gutters, "amount")
          : 0;
      const _warehousesTotal =
        _warehouses === 0
          ? _warehouses
          : _warehouses.length > 0
          ? sumBy(_warehouses, "amount")
          : 0;
      const _warrantiesTotal =
        _warranties === 0
          ? _warranties
          : _warranties.length > 0
          ? sumBy(_warranties, "amount")
          : 0;

      if (details.propertyType === "New Construction") {
        // _overHead = _collectionsTotal * 0.06;
        _overHead = _collectionsTotal * 0.15;
      } else {
        // _overHead = _collectionsTotal * 0.12;
        _overHead = _collectionsTotal * 0.15;
      }

      let _balance =
        _collectionsTotal -
        _overHead -
        _materialsTotal -
        _laborTotal -
        _dumpingTotal -
        _permitsTotal -
        _extraTotal -
        _creditCardsTotal -
        _financingsTotal -
        _guttersTotal -
        _warehousesTotal -
        _warrantiesTotal -
        _stock;

      let _profitPercentage = (_balance / _collectionsTotal) * 100;

      // TODO: swap out new logic for decreased commissions if _profitPercentage is < 17
      const contractDate = new Date(details.contractDate);
      const cutoff17Under17 = new Date(
        "Tue Apr 30 2024 20:00:00 GMT-0400 (Eastern Daylight Time)"
      );
      // const cutoff17Under17 = new Date();
      if (
        // _profitPercentage < 17 &&
        // details.propertyType !== "New Construction" &&
        contractDate > cutoff17Under17
      ) {
        // _overHead = _collectionsTotal * 0.17;
        if (details.propertyType === "New Construction") {
          // _overHead = _collectionsTotal * 0.13;
          _overHead = _collectionsTotal * 0.15;
        } else {
          // _overHead = _collectionsTotal * 0.13;
          _overHead = _collectionsTotal * 0.15;
        }

        _balance =
          _collectionsTotal -
          _overHead -
          _materialsTotal -
          _laborTotal -
          _dumpingTotal -
          _permitsTotal -
          _extraTotal -
          _creditCardsTotal -
          _financingsTotal -
          _guttersTotal -
          _warehousesTotal -
          _warrantiesTotal -
          _stock;

        _profitPercentage = (balance / collectionsTotal) * 100;
      }

      const _managerFee = managerFeeRate * _balance;
      const _officeFee = officeFeeRate * _balance;
      const _regularCommission =
        officeFeeRate === 0
          ? (_balance * 0.5) / salesLength
          : (_balance * 0.3) / salesLength;
      const _salaryCommission =
        officeFeeRate === 0
          ? (_balance * 0.25) / salesLength
          : (_balance * 0.15) / salesLength;
      const _joshHRegularComission =
        officeFee === 0
          ? (balance * 0.45) / salesLength
          : (balance * 0.3) / salesLength;
      const _newRegularComission =
        officeFee === 0
          ? (balance * 0.4) / salesLength
          : (balance * 0.25) / salesLength;
      const _managerCommission =
        officeFeeRate === 0
          ? (_balance * 0.55) / salesLength
          : (_balance * 0.35) / salesLength;
      const ncCommission =
        officeFeeRate === 0
          ? (balance * 0.06) / salesLength
          : (balance * 0.03) / salesLength;

      const commissions = [];
      for (const s of details.salesObj) {
        let obj = {};
        obj.employee = s._user;
        obj.type = "Commission";
        if (
          s.name === "Ryan Balcom" ||
          s.name === "Rick Zuniga" ||
          s.name === "Josh Bell"
        ) {
          // Do not assign a commission
        } else if (s.name === "Joshua Homrich") {
          obj.amount = _joshHRegularComission;
          commissions.push(obj);
        } else if (
          s.name === "Andrew Story" ||
          s.name === "Tavis Polinskey" ||
          s.name === "Marcus Ferrell"
        ) {
          obj.amount = _newRegularComission;
          commissions.push(obj);
        } else if (s.name === "Pat Cisler") {
          obj.amount = ncCommission;
          commissions.push(obj);
        } else if (s.name === "Josh Bell") {
          obj.amount = _managerCommission;
          commissions.push(obj);
        } else {
          obj.amount = _regularCommission;
          commissions.push(obj);
        }
      }

      if (managerFeeRate !== 0) {
        commissions.push({
          employee: "5ce185e8ce894a0031b7c7bb",
          type: "Manager Commission",
          amount: _managerFee,
        });
      }

      form.setFieldsValue({
        commissions,
        overHead: _overHead,
      });

      unstable_batchedUpdates(() => {
        setBalance(_balance);
        setOverHead(_overHead);
        setMaterialTotal(_materialsTotal);
        setLaborTotal(_laborTotal);
        setDumpsterTotal(_dumpingTotal);
        setPermitTotal(_permitsTotal);
        setExtraTotal(_extraTotal);
        setCollectionsTotal(_collectionsTotal);
        setStockTotal(_stock);
        setProfitPercentage(_profitPercentage);

        setCreditCardTotal(_creditCardsTotal);
        setFinancingTotal(_financingsTotal);
        setWarehouseTotal(_warehousesTotal);
        setWarrantyTotal(_warrantiesTotal);
        setGuttersTotal(_guttersTotal);

        setManagerFee(_managerFee);
        setOfficeFee(_officeFee);
        setRegularCommission(_regularCommission);
        setSalaryCommission(_salaryCommission);
        setNewRegularComission(_newRegularComission);
        setManagerCommission(_managerCommission);
        setJoshHRegularCommission(_joshHRegularComission);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleNoBreakdown = () => {
    Modal.confirm({
      zIndex: 4002,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Confirm",
      okButtonProps: { danger: true },
      content: <div>Are you sure this project should not be brokedown?</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          noBreakdown(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const noBreakdown = useCallback(
    async (cb) => {
      try {
        await api.post("/needsBrokedown/noBreakdown", { _id: formDetails._id });

        dispatch(
          fetchTable({
            table,
            col,
            order,
            search,
            activeFilter,
            fetching: false,
          })
        );

        message.success("Breakdown cancelled");
        cb();
        handleClose(true);
      } catch (err) {
        console.log("err", err);
        message.error("Error deleting builder");
      }
    },
    [
      formDetails._id,
      dispatch,
      table,
      col,
      order,
      search,
      activeFilter,
      handleClose,
    ]
  );

  const openActivity = useCallback(() => {
    dispatch(
      openForm("activity", {
        _id: formDetails._id,
        customerId: formDetails.customerId,
        customerName: formDetails.customerName,
        projectStatus: "Sold",
        projectManager: {
          name: details.salesObj.map((s) => s.name).join(", "),
        },
      })
    );
  }, [dispatch, formDetails, details]);

  const goToInNewTab = () => {
    openInNewTab(
      `https://story.gopremier.net/projects/view/details/${formDetails._id}`
    );
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      // onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      className="form"
    >
      <RemoveAutocomplete />

      <FormBody>
        <div
          className="content-inner"
          ref={setScrollContainer}
          style={{
            paddingLeft: 24,
            paddingTop: 24,
            paddingRight: 24,
            paddingBottom: 8,
            maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
          }}
        >
          {/* <div style={{ overflow: "scroll" }}> */}
          <PrintBreakdown
            ref={printRef}
            customerId={formDetails.customerId}
            collectionsTotal={collectionsTotal}
            materialTotal={materialTotal}
            laborTotal={laborTotal}
            overHead={overHead}
            dumpsterTotal={dumpsterTotal}
            permitTotal={permitTotal}
            stockTotal={stockTotal}
            extraTotal={extraTotal}
            creditCardTotal={creditCardTotal}
            financingTotal={financingTotal}
            warehouseTotal={warehouseTotal}
            warrantyTotal={warrantyTotal}
            guttersTotal={guttersTotal}
            balance={balance}
            expenses={expenses}
          />
          {/* </div> */}

          <div className={feedBack.progress !== 0 ? "hide" : ""}>
            <Button
              style={{ marginBottom: 24 }}
              onClick={goToInNewTab}
              type="primary"
              block
            >
              Go To In New Tab
            </Button>

            <ProjectDetails
              customerId={formDetails.customerId}
              customerName={formDetails.customerName}
              projectAddress={formDetails.projectAddress}
            />

            <Divider />

            <Row>
              <Col xs={24}>
                <Typography.Title level={3}>Documents</Typography.Title>
              </Col>
            </Row>

            {documents.length > 0 && (
              <HorizontalScroll
                ref={documentRef}
                scrollContainer={scrollContainer}
              >
                {documents.map((media) => (
                  <Document
                    key={media._id}
                    media={media}
                    scrollContainer={documentRef}
                  />
                ))}
              </HorizontalScroll>
            )}

            <Divider />

            <ScopeDetails scopes={scopes} />

            <Divider style={{ marginBottom: 0 }} />

            {/* <Row>
              <Col xs={24}>
                <Typography.Title level={3}>Promotions</Typography.Title>
              </Col>
              <Col xs={24}>
                <Typography.Text>
                  All Projects with contracts signed on or between August 1st,
                  2022 - August 31st, 2022 using Same Day As Cash will have
                  their financing fee waived
                </Typography.Text>
              </Col>
              <Col xs={24}>
                <Typography.Text strong>Contract Date: </Typography.Text>
                <Typography.Text
                  type={promotionEligible ? "success" : "danger"}
                >
                  {dayjs(details.contractDate).format("MMMM D, YYYY")} -{" "}
                  {promotionEligible ? "ELIGIBLE" : "NOT ELIGIBLE"}
                </Typography.Text>
              </Col>
            </Row> */}

            {/* <Divider /> */}

            {/* MATERIALS */}

            <Affix target={() => scrollContainer}>
              <Row
                justify="center"
                gutter={16}
                style={{
                  background: "#f2f2f2",
                  paddingTop: 12,
                }}
              >
                <Col xs={5}>
                  <Typography.Text strong>Exp. Materails:</Typography.Text>
                  <br />
                  <Typography.Text type="secondary">
                    {currencyFormatter(details.expectedMaterials)}
                  </Typography.Text>
                </Col>

                <Col xs={5}>
                  <Typography.Text strong>Actual Materials:</Typography.Text>
                  <div style={{ marginBottom: 8 }}>
                    <Typography.Text type="secondary">
                      {currencyFormatter(materialTotal)}
                    </Typography.Text>
                  </div>
                </Col>
              </Row>
            </Affix>

            <Row justify="center" style={{ marginTop: 24, marginBottom: 24 }}>
              <Col xs={5}></Col>

              <Col xs={5}>
                <ExpenseColumn
                  type="materials"
                  onChange={handleExpenseChange}
                  allowDelete
                />
              </Col>
            </Row>

            {/* <Divider /> */}

            {/* OVERHEAD */}

            <Affix target={() => scrollContainer}>
              <Row
                justify="center"
                gutter={16}
                style={{
                  background: "#f2f2f2",
                  paddingTop: 12,
                }}
              >
                <Col xs={5}>
                  <Typography.Text strong>Exp. Overhead:</Typography.Text>
                  <br />
                  <Typography.Text type="secondary">
                    {currencyFormatter(details.expectedOverhead)}
                  </Typography.Text>
                </Col>

                <Col xs={5}>
                  <Typography.Text strong>Actual Overhead:</Typography.Text>
                  <div style={{ marginBottom: 8 }}>
                    <Typography.Text type="secondary">
                      {currencyFormatter(overHead)}
                    </Typography.Text>
                  </div>
                </Col>
              </Row>
            </Affix>

            <Row justify="center" style={{ marginTop: 24, marginBottom: 24 }}>
              <Col xs={5}></Col>

              <Col xs={5}>
                <Form.Item
                  name="overHead"
                  rules={[{ required: true, message: "Overhead is required" }]}
                  // disabled
                  style={{ marginBottom: 0 }}
                >
                  <Currency
                    id="overHead"
                    size="small"
                    onChange={handleOverheadChange}
                    // disabled
                  />
                  {/* <InputNumber
                    id="overHead"
                    // autoComplete="dont-use-autocomplete"
                    // aligh="left"
                    onChange={handleExpenseChange}
                    size="small"
                    // autoFocus={false}
                    // formatter={(value) => {
                    //   if (!value) return value;
                    //   value = Math.round(value * 100) / 100;
                    //   return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    // }}
                    // parser={(value) => {
                    //   return value.replace(/\$\s?|(,*)/g, "");
                    // }}
                    // precision={2}
                  /> */}
                </Form.Item>
              </Col>
            </Row>

            {/* <Divider /> */}

            {/* LABOR */}

            <Affix target={() => scrollContainer}>
              <Row
                justify="center"
                gutter={16}
                style={{
                  background: "#f2f2f2",
                  paddingTop: 12,
                }}
              >
                <Col xs={5}>
                  <Typography.Text strong>Exp. Labor:</Typography.Text>
                  <br />
                  <Typography.Text type="secondary">
                    {currencyFormatter(details.expectedLabor)}
                  </Typography.Text>
                </Col>

                <Col xs={5}>
                  <Typography.Text strong>Actual Labor:</Typography.Text>
                  <div style={{ marginBottom: 8 }}>
                    <Typography.Text type="secondary">
                      {currencyFormatter(laborTotal)}
                    </Typography.Text>
                  </div>
                </Col>
              </Row>
            </Affix>

            <Row justify="center" style={{ marginTop: 24, marginBottom: 24 }}>
              <Col xs={5}></Col>

              <Col xs={5}>
                <ExpenseColumn
                  type="labor"
                  onChange={handleExpenseChange}
                  allowDelete
                />
              </Col>
            </Row>

            {/* <Divider /> */}

            {/* GUTTERS */}

            <Affix target={() => scrollContainer}>
              <Row
                justify="center"
                gutter={16}
                style={{
                  background: "#f2f2f2",
                  paddingTop: 12,
                }}
              >
                <Col xs={5}>
                  <Typography.Text strong>Exp. Gutters:</Typography.Text>
                  <br />
                  <Typography.Text type="secondary">
                    {currencyFormatter(0)}
                  </Typography.Text>
                </Col>

                <Col xs={5}>
                  <Typography.Text strong>Actual Gutters:</Typography.Text>
                  <div style={{ marginBottom: 8 }}>
                    <Typography.Text type="secondary">
                      {currencyFormatter(guttersTotal)}
                    </Typography.Text>
                  </div>
                </Col>
              </Row>
            </Affix>

            <Row justify="center" style={{ marginTop: 24, marginBottom: 24 }}>
              <Col xs={5}></Col>

              <Col xs={5}>
                <ExpenseColumn
                  type="gutters"
                  onChange={handleExpenseChange}
                  allowDelete
                />
              </Col>
            </Row>

            {/* <Divider /> */}

            {/* DUMPING */}

            <Affix target={() => scrollContainer}>
              <Row
                justify="center"
                gutter={16}
                style={{
                  background: "#f2f2f2",
                  paddingTop: 12,
                }}
              >
                <Col xs={5}>
                  <Typography.Text strong>Exp. Dumping:</Typography.Text>
                  <br />
                  <Typography.Text type="secondary">
                    {currencyFormatter(details.expectedDumping)}
                  </Typography.Text>
                </Col>

                <Col xs={5}>
                  <Typography.Text strong>Actual Dumping:</Typography.Text>
                  <div style={{ marginBottom: 8 }}>
                    <Typography.Text type="secondary">
                      {currencyFormatter(dumpsterTotal)}
                    </Typography.Text>
                  </div>
                </Col>
              </Row>
            </Affix>

            <Row justify="center" style={{ marginTop: 24, marginBottom: 24 }}>
              <Col xs={5}></Col>

              <Col xs={5}>
                <ExpenseColumn
                  type="dumping"
                  onChange={handleExpenseChange}
                  allowDelete
                />
              </Col>
            </Row>

            {/* <Divider /> */}

            {/* Extras */}

            <Affix target={() => scrollContainer}>
              <Row
                justify="center"
                gutter={16}
                style={{
                  background: "#f2f2f2",
                  paddingTop: 12,
                }}
              >
                <Col xs={5}>
                  <Typography.Text strong>Exp. Extras:</Typography.Text>
                  <br />
                  <Typography.Text type="secondary">
                    {currencyFormatter(details.expectedExtras)}
                  </Typography.Text>
                </Col>

                <Col xs={5}>
                  <Typography.Text strong>Actual Extras:</Typography.Text>
                  <div style={{ marginBottom: 8 }}>
                    <Typography.Text type="secondary">
                      {currencyFormatter(extraTotal)}
                    </Typography.Text>
                  </div>
                </Col>
              </Row>
            </Affix>

            <Row justify="center" style={{ marginTop: 24, marginBottom: 24 }}>
              <Col xs={5}></Col>

              <Col xs={5}>
                <ExpenseColumn
                  type="extra"
                  onChange={handleExpenseChange}
                  allowDelete
                />
              </Col>
            </Row>

            {/* <Divider /> */}

            {/* PERMITS */}

            <Affix target={() => scrollContainer}>
              <Row
                justify="center"
                gutter={16}
                style={{
                  background: "#f2f2f2",
                  paddingTop: 12,
                }}
              >
                <Col xs={5}>
                  <Typography.Text strong>Exp. Permits:</Typography.Text>
                  <br />
                  <Typography.Text type="secondary">
                    {currencyFormatter(0)}
                  </Typography.Text>
                </Col>

                <Col xs={5}>
                  <Typography.Text strong>Actual Permits:</Typography.Text>
                  <div style={{ marginBottom: 8 }}>
                    <Typography.Text type="secondary">
                      {currencyFormatter(permitTotal)}
                    </Typography.Text>
                  </div>
                </Col>
              </Row>
            </Affix>

            <Row justify="center" style={{ marginTop: 24, marginBottom: 24 }}>
              <Col xs={5}></Col>

              <Col xs={5}>
                <ExpenseColumn
                  type="permits"
                  onChange={handleExpenseChange}
                  allowDelete
                />
              </Col>
            </Row>

            {/* <Divider /> */}

            {/* CREDIT CARD FEES */}

            <Affix target={() => scrollContainer}>
              <Row
                justify="center"
                gutter={16}
                style={{
                  background: "#f2f2f2",
                  paddingTop: 12,
                }}
              >
                <Col xs={5}>
                  <Typography.Text strong>
                    Exp. Credit Card Fees:
                  </Typography.Text>
                  <br />
                  <Typography.Text type="secondary">
                    {currencyFormatter(0)}
                  </Typography.Text>
                </Col>

                <Col xs={5}>
                  <Typography.Text strong>
                    Actual Credit Card Fees:
                  </Typography.Text>
                  <div style={{ marginBottom: 8 }}>
                    <Typography.Text type="secondary">
                      {currencyFormatter(creditCardTotal)}
                    </Typography.Text>
                  </div>
                </Col>
              </Row>
            </Affix>

            <Row justify="center" style={{ marginTop: 24, marginBottom: 24 }}>
              <Col xs={5}></Col>

              <Col xs={5}>
                <ExpenseColumn
                  type="creditCards"
                  onChange={handleExpenseChange}
                  allowDelete
                />
              </Col>
            </Row>

            {/* <Divider /> */}

            {/* FINANCING FEES */}

            <Affix target={() => scrollContainer}>
              <Row
                justify="center"
                gutter={16}
                style={{
                  background: "#f2f2f2",
                  paddingTop: 12,
                }}
              >
                <Col xs={5}>
                  <Typography.Text strong>Exp. Financing Fees:</Typography.Text>
                  <br />
                  <Typography.Text type="secondary">
                    {currencyFormatter(0)}
                  </Typography.Text>
                </Col>

                <Col xs={5}>
                  <Typography.Text strong>
                    Actual Financing Fees:
                  </Typography.Text>
                  <div style={{ marginBottom: 8 }}>
                    <Typography.Text type="secondary">
                      {currencyFormatter(financingTotal)}
                    </Typography.Text>
                  </div>
                </Col>
              </Row>
            </Affix>

            <Row justify="center" style={{ marginTop: 24, marginBottom: 24 }}>
              <Col xs={5}></Col>

              <Col xs={5}>
                <ExpenseColumn
                  type="financings"
                  onChange={handleExpenseChange}
                  allowDelete
                />
              </Col>
            </Row>

            {/* <Divider /> */}

            {/* WAREHOUSE */}

            <Affix target={() => scrollContainer}>
              <Row
                justify="center"
                gutter={16}
                style={{
                  background: "#f2f2f2",
                  paddingTop: 12,
                }}
              >
                <Col xs={5}>
                  <Typography.Text strong>Exp. Warehouse:</Typography.Text>
                  <br />
                  <Typography.Text type="secondary">
                    {currencyFormatter(0)}
                  </Typography.Text>
                </Col>

                <Col xs={5}>
                  <Typography.Text strong>Actual Warehouse:</Typography.Text>
                  <div style={{ marginBottom: 8 }}>
                    <Typography.Text type="secondary">
                      {currencyFormatter(warehouseTotal)}
                    </Typography.Text>
                  </div>
                </Col>
              </Row>
            </Affix>

            <Row justify="center" style={{ marginTop: 24, marginBottom: 24 }}>
              <Col xs={5}></Col>

              <Col xs={5}>
                <ExpenseColumn
                  type="warehouses"
                  onChange={handleExpenseChange}
                  allowDelete
                />
              </Col>
            </Row>

            {/* <Divider /> */}

            {/* WAREHOUSE */}

            <Affix target={() => scrollContainer}>
              <Row
                justify="center"
                gutter={16}
                style={{
                  background: "#f2f2f2",
                  paddingTop: 12,
                }}
              >
                <Col xs={5}>
                  <Typography.Text strong>Exp. Warranty Fees:</Typography.Text>
                  <br />
                  <Typography.Text type="secondary">
                    {currencyFormatter(0)}
                  </Typography.Text>
                </Col>

                <Col xs={5}>
                  <Typography.Text strong>
                    Actual Wararnty Fees:
                  </Typography.Text>
                  <div style={{ marginBottom: 8 }}>
                    <Typography.Text type="secondary">
                      {currencyFormatter(warrantyTotal)}
                    </Typography.Text>
                  </div>
                </Col>
              </Row>
            </Affix>

            <Row justify="center" style={{ marginTop: 24, marginBottom: 24 }}>
              <Col xs={5}></Col>

              <Col xs={5}>
                <ExpenseColumn
                  type="warranties"
                  onChange={handleExpenseChange}
                  allowDelete
                />
              </Col>
            </Row>

            {/* <Divider /> */}

            {/* COLLECTIONS */}

            <Affix target={() => scrollContainer}>
              <Row
                justify="center"
                gutter={16}
                style={{
                  background: "#f2f2f2",
                  paddingTop: 12,
                }}
              >
                <Col xs={5}>
                  <Typography.Text strong>Exp. Collections:</Typography.Text>
                  <br />
                  <Typography.Text type="secondary">
                    {currencyFormatter(details.originalContractPrice)}
                  </Typography.Text>
                </Col>

                <Col xs={5}>
                  <Typography.Text strong>Actual Collections:</Typography.Text>
                  <div style={{ marginBottom: 8 }}>
                    <Typography.Text type="secondary">
                      {currencyFormatter(collectionsTotal)}
                    </Typography.Text>
                  </div>
                </Col>
              </Row>
            </Affix>

            <Row justify="center" style={{ marginTop: 24, marginBottom: 24 }}>
              <Col xs={5}></Col>

              <Col xs={5}>
                <ExpenseColumn
                  type="collections"
                  onChange={handleExpenseChange}
                  allowDelete
                />
              </Col>
            </Row>

            <Divider />

            {/* <BreakdownDetailsExpected
              expectedMaterials={details.expectedMaterials}
              expectedOverhead={details.expectedOverhead}
              expectedLabor={details.expectedLabor}
              expectedDumping={details.expectedDumping}
              expectedExtras={details.expectedExtras}
              originalContractPrice={details.originalContractPrice}
            />

            <Divider />

            <Row gutter={16}>
              <Col xs={3} style={{ borderRight: "1px solid #ededed" }}>
                <Typography.Text strong>Materials:</Typography.Text>
                <div style={{ marginBottom: 8 }}>
                  <Typography.Text type="secondary">
                    {currencyFormatter(materialTotal)}
                  </Typography.Text>
                </div>
                <ExpenseColumn
                  type="materials"
                  onChange={handleExpenseChange}
                  allowDelete
                />
              </Col>
              <Col xs={3} style={{ borderRight: "1px solid #ededed" }}>
                <Typography.Text strong>Overhead:</Typography.Text>
                <div style={{ marginBottom: 8 }}>
                  <Typography.Text type="secondary">
                    {currencyFormatter(overHead)}
                  </Typography.Text>
                </div>
                <Form.Item
                  name="overHead"
                  rules={[{ required: true, message: "Amount is required" }]}
                  disabled
                >
                  <Currency
                    id="overHead"
                    size="small"
                    onChange={handleExpenseChange}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col xs={3} style={{ borderRight: "1px solid #ededed" }}>
                <Typography.Text strong>Labor:</Typography.Text>
                <div style={{ marginBottom: 8 }}>
                  <Typography.Text type="secondary">
                    {currencyFormatter(laborTotal)}
                  </Typography.Text>
                </div>
                <ExpenseColumn
                  type="labor"
                  onChange={handleExpenseChange}
                  allowDelete
                />
              </Col>
              <Col xs={3} style={{ borderRight: "1px solid #ededed" }}>
                <Typography.Text strong>Dumping:</Typography.Text>
                <div style={{ marginBottom: 8 }}>
                  <Typography.Text type="secondary">
                    {currencyFormatter(dumpsterTotal)}
                  </Typography.Text>
                </div>
                <ExpenseColumn
                  type="dumping"
                  onChange={handleExpenseChange}
                  allowDelete
                />
              </Col>
              <Col xs={3} style={{ borderRight: "1px solid #ededed" }}>
                <Typography.Text strong>Permits:</Typography.Text>
                <div style={{ marginBottom: 8 }}>
                  <Typography.Text type="secondary">
                    {currencyFormatter(permitTotal)}
                  </Typography.Text>
                </div>
                <ExpenseColumn
                  type="permits"
                  onChange={handleExpenseChange}
                  allowDelete
                />
              </Col>
              <Col xs={3} style={{ borderRight: "1px solid #ededed" }}>
                <Typography.Text strong>Extra:</Typography.Text>
                <div style={{ marginBottom: 8 }}>
                  <Typography.Text type="secondary">
                    {currencyFormatter(extraTotal)}
                  </Typography.Text>
                </div>
                <ExpenseColumn
                  type="extra"
                  onChange={handleExpenseChange}
                  allowDelete
                />
              </Col>
              <Col xs={3} style={{ borderRight: "1px solid #ededed" }}>
                <Typography.Text strong>Stock:</Typography.Text>
                <div style={{ marginBottom: 8 }}>
                  <Typography.Text type="secondary">
                    {currencyFormatter(stockTotal)}
                  </Typography.Text>
                </div>
                <Form.Item
                  name="stock"
                  rules={[{ required: true, message: "Amount is required" }]}
                >
                  <Currency
                    id="stock"
                    size="small"
                    onChange={handleExpenseChange}
                  />
                </Form.Item>
              </Col>
              <Col xs={3}>
                <Typography.Text strong>Collections:</Typography.Text>
                <div style={{ marginBottom: 8 }}>
                  <Typography.Text type="secondary">
                    {currencyFormatter(collectionsTotal)}
                  </Typography.Text>
                </div>
                <ExpenseColumn
                  type="collections"
                  onChange={handleExpenseChange}
                />
              </Col>
            </Row> */}

            <Row gutter={16} justify="center">
              <Col
                xs={10}
                // offset={20}
                style={{ textAlign: "right" }}
              >
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  Balance:
                </Typography.Title>
                <Typography.Text type="secondary">
                  {currencyFormatter(balance)}
                </Typography.Text>
                <br />
                <br />

                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  Profit Percentage:
                </Typography.Title>
                <Typography.Text type="secondary">
                  {percentFormatter(profitPercentage)}
                </Typography.Text>
              </Col>
            </Row>

            <Divider />

            <PricingDetails />

            <Divider />

            <CommissionDetails
              salesObj={details.salesObj}
              origin={details.origin}
              officeFee={officeFee}
              regularCommission={regularCommission}
              managerCommission={managerCommission}
              newRegularComission={newRegularComission}
              joshHRegularComission={joshHRegularComission}
              salaryCommission={salaryCommission}
              managerFee={managerFee}
            />

            <Commissions employees={employees} />

            <Button
              onClick={openActivity}
              type="primary"
              danger
              shape="circle"
              className={styles.fab}
            >
              <Post />
            </Button>
          </div>

          <div
            style={{ height: "100%" }}
            className={feedBack.progress === 0 ? "hide" : ""}
          >
            <ProcessFeedback feedBack={feedBack} steps={steps} />
          </div>
        </div>
      </FormBody>
      {feedBack.progress === 0 && (
        <FormControls className="sticky-footer">
          <CancelButton handleClick={handleClose} />

          <Button
            style={{ marginRight: 8 }}
            danger
            type="default"
            onClick={handleNoBreakdown}
          >
            No Breakdown
          </Button>

          <Button
            style={{ marginRight: 8 }}
            type="default"
            onClick={handlePrint}
          >
            Print
          </Button>

          <Button
            // htmlType="submit"
            type="primary"
            loading={loading}
            disabled={loading || !unsavedChanges}
            onClick={onFinish}
          >
            Submit
          </Button>
        </FormControls>
      )}
    </Form>
  );
};

export default NeedsBrokedown;
